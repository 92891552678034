<template>
  <div class="soundtrack">
    <vue-plyr ref="plyr">
      <audio crossorigin playsinline>
        <source :src="`${src}.mp3`" type="audio/mp3" />
        <source :src="`${src}.ogg`" type="audio/ogg" />
      </audio>
    </vue-plyr>
    <button v-if="ready" @click="togglePlay" class="mute-button button">
      <svg viewBox="0 0 24 24">
        <g>
          <polygon
            fill="none"
            stroke="#FFFFFF"
            stroke-miterlimit="10"
            points="13.14,2.75 13.14,21.22 4.99,15.62 0.99,15.62 0.99,8.23 4.99,8.23"
          />
          <path
            fill="none"
            stroke="#FFFFFF"
            stroke-miterlimit="10"
            d="M19.64,19.97c2.1-2.01,3.3-4.95,3.3-8.06 s-1.2-6.05-3.3-8.06"
          />
          <path
            fill="none"
            stroke="#FFFFFF"
            stroke-miterlimit="10"
            d="M17.33,17.66c1.47-1.43,2.32-3.53,2.32-5.75 c0-2.22-0.84-4.32-2.32-5.75"
          />
          <path
            fill="none"
            stroke="#FFFFFF"
            stroke-miterlimit="10"
            d="M14.92,15.38c0.93-0.84,1.46-2.08,1.46-3.39 c0-1.31-0.53-2.54-1.46-3.39"
          />
          <line
            v-if="!isPlaying"
            fill="none"
            stroke="#FFFFFF"
            stroke-miterlimit="10"
            x1="0.82"
            y1="23.08"
            x2="23.18"
            y2="0.64"
          />
        </g>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: ["source"],
  data() {
    return {
      player: null,
      isPlaying: null,
    };
  },
  computed: {
    ready() {
      return this.isPlaying !== null;
    },
    src() {
      return "https://nickjeffrey.com/files/mp3/" + this.source;
    },
  },
  watch: {
    ready() {
      document.addEventListener("keydown", this.keyPress);
    },
  },
  methods: {
    keyPress(e) {
      if (e.keyCode === 77) this.togglePlay();
    },
    togglePlay() {
      this.player.togglePlay();
    },
  },
  mounted() {
    this.player = this.$refs.plyr.player;
    this.player.on("playing", () => {
      this.isPlaying = true;
      this.player.volume = 1;
    });
    this.player.on("pause", () => {
      this.isPlaying = false;
    });
    this.player.on("ready", () => {
      let promise = this.player.play();
      if (promise !== undefined) {
        promise
          .then(() => {
            this.isPlaying = true;
          })
          .catch(() => {
            this.isPlaying = false;
          });
      }
    });
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.keyPress);
  },
};
</script>

<style>
.soundtrack {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  mix-blend-mode: difference;
}

.soundtrack .plyr--audio {
  display: none;
}

.soundtrack .mute-button svg {
  height: 24px;
  width: 24px;
  vertical-align: middle;
}
</style>
